export default {
  props: {
    value: Object
  },
  data () {
    return {
      realTimeLockedFields: ''
    }
  },
  watch: {
    lockedFields: {
      handler: function (val, oldVal) {
        if (!val) {
          this.realTimeLockedFields = ''
        } else {
          this.realTimeLockedFields = val
        }
      },
      immediate: true
    }
  },
  computed: {
    lockedFields () {
      return this.value?.lockedFields || ''
    }
  },
  methods: {
    updateExtensionState (payload) {
      this.$emit('change', {
        ...this.value,
        dirty: true,
        ...payload
      })
    },
    labelizeExtensionTooltip (extensionPlan) {
      if (extensionPlan && extensionPlan.extensionType) {
        switch (extensionPlan.extensionType) {
          case 'priceExtensionPlan':
            if (extensionPlan.priceExtensionPriceTableRows && extensionPlan.priceExtensionPriceTableRows.length > 0) {
              const string = extensionPlan.priceExtensionPriceTableRows
                .map(priceRow => `${priceRow.header || ''} - ${extensionPlan.priceQualifier === 'NONE' ? '' : extensionPlan.priceQualifier} ${(parseFloat(priceRow.price) || 0) < 10000 ? this.$filters.currency(priceRow.price) : '$' + (Math.round(parseFloat((priceRow.price || 0) / 1000))) + 'K'} ${!priceRow.priceUnit || priceRow.priceUnit === 'NONE' ? '' : priceRow.priceUnit}`)
                .join('\n')
              return this.$filters.labelizeGeneric(string)
            } else {
              // fallback unlikely, but who knows
              return this.$filters.labelizeGeneric(extensionPlan.priceExtensionType)
            }
          default:
            return this.labelizeExtensionName(extensionPlan)
        }
      }
      return ''
    },
    labelizeExtensionName (extensionPlan) { // needed by extensionNameDisplay and manageType
      if (extensionPlan && extensionPlan.extensionType) {
        switch (extensionPlan.extensionType) {
          case 'siteLinkPlan':
            return extensionPlan.siteLinkText
          case 'structuredSnippetPlan':
            return `${extensionPlan.heading}: ${extensionPlan.value ? extensionPlan.value.replace(/::/gi, ':').replace(/:/gi, ', ') : ''}`
          case 'calloutPlan':
            return extensionPlan.calloutText
          case 'callExtensionPlan':
            return this.$filters.phoneNumber(extensionPlan.phoneNumber)
          case 'locationExtensionPlan':
            return extensionPlan.businessName
          case 'priceExtensionPlan':
            const plans = this.labelizeExtensionTooltip(extensionPlan).split('\n')
            return plans[0] + `${plans.length > 1 ? ' ...' : ''}`
          case 'imageExtensionPlan':
            return extensionPlan.image
          case 'promotionExtensionPlan':
            return `${this.$filters.convertFromCapsUnderscore(extensionPlan.occasion)} - ${extensionPlan.promotionTarget}`
        }
      }
      return ''
    }
  }
}
